import React, { useEffect, useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import InputPanel from '../../components/InputPanel/InputPanel';
import RateComparisonChart from '../../components/RateComparisonChart/RateComparisonChart';
import SelectCurrentRate from '../../components/InputComponents/SelectCurrentRate/SelectCurrentRate';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import SelectRateComparisonVehicle from '../../components/InputComponents/SelectRateComparisonVehicle/SelectRateComparisonVehicle';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import SlideMilesDrivenAnnually from '../../../components/InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually';
import SelectChargingPattern from '../../components/InputComponents/SelectChargingPattern/SelectChargingPattern';
import SlideElectricMilesPortionForPhev from '../../../components/InputComponents/SlideElectricMilesPortionForPhev/SlideElectricMilesPortionForPhev';
import SlideCurrentMonthlyBill from '../../components/InputComponents/SlideCurrentMonthlyBill/SlideCurrentMonthlyBill';
import SlideGasolinePrice from '../../../components/InputComponents/SlideGasolinePrice/SlideGasolinePrice';
import SlideEquivalentMilesPerGallon from '../../components/InputComponents/SlideEquivalentMilesPerGallon/SlideEquivalentMilesPerGallon';
import SlidePublicChargingPercentage from '../../components/InputComponents/SlidePublicChargingPercentage/SlidePublicChargingPercentage';
import SelectPublicChargingCost from '../../components/InputComponents/SelectPublicChargingCost/SelectPublicChargingCost';

// Data
import rates from '../../data/rates';
import chargingPatterns from '../../data/chargingPatterns';
import zipcodeToBaselineAllocationRegion from '../../data/zipcodeToBaselineAllocationRegion.json';

// Functions
import isPHEV from '../../../functions/vehicle/isPHEV';
import { FormatAsCents } from '../../../utils/Helpers/Format';
import getLoadProfileData from '../../data/loadProfiles/getLoadProfileData';
import totalElectricVehicleKwhPerYear from '../../functions/totalElectricVehicleKwhPerYear';
import RateCostCalculator from '../../functions/RateCostCalculator';
import GasolineCostCalculator from '../../functions/GasolineCostCalculator';
import calculateRateTotals from '../../functions/calculateRateTotals';
import rateComparisonChartData from '../../functions/rateComparisonChartData';

// Components
import RateComparisonBanner from '../../components/RateComparisonBanner/RateComparisonBanner';
import RateDetails from '../../components/RateDetails/RateDetails';
import RateOptionsWizard from '../../components/RateOptionsWizard/RateOptionsWizard';

// Styles
import './RateComparison.scss';

import { useIntl } from 'react-intl';

const RateComparison = ({ electricVehicles, ip, uuid }) => {
  const intl = useIntl();
  useEffect(() => {
    document.title =
      process.env.REACT_APP_PAGES_RATES_TITLE || 'PSEG Rate Advisor';
  });

  const userPrefs = useContext(UserPrefsContext);
  const {
    isComparingLowestRateOnly,
    vehicleIdForRateComparison,
    chargingPatternId,
    currentRateId,
    zipcode,
    hasSolarAtHome,
    milesDrivenAnnually,
    electricMilesPortionForPhev,
    publicChargingPercentage,
    publicChargingCostInCentsPerKwh,
    currentMonthlyBill,
    gasolinePriceInCentsPerGal,
    equivalentMilesPerGallon,
    selectedEnergyCostIds,
    currentRateClimateZone,
  } = userPrefs.get();

  const [selectedVehicle, setSelectedVehicle] = useState();
  const [bestSaving, setBestSaving] = useState();

  useEffect(() => {
    if (electricVehicles && vehicleIdForRateComparison) {
      setSelectedVehicle(
        electricVehicles.find(
          (ev) => ev.electric_vehicle_id === vehicleIdForRateComparison
        )
      );
    } else if (electricVehicles) {
      setSelectedVehicle(electricVehicles[0]);
    }
  }, [electricVehicles, vehicleIdForRateComparison]);
  const currentChargingPattern =
    chargingPatterns.find((p) => p.id === chargingPatternId) ||
    chargingPatterns[0];
  const currentTerritory = zipcodeToBaselineAllocationRegion[zipcode] || '8';
  const currentRate =
    Object.values(rates).find((e) => e.id === currentRateId) ||
    rates[currentRateId] ||
    rates[Object.keys(rates)[0]];
  const evaluatedCurrentRate = {
    ...currentRate,
    data: currentRate.data(currentTerritory, currentRateClimateZone),
  };

  const homeLoadProfile = getLoadProfileData({
    rateGroup: currentRateId,
    climateZone: currentRateClimateZone,
    hasSolar: hasSolarAtHome,
  });

  const totalElectricVehicleKwh = totalElectricVehicleKwhPerYear({
    kwhPer100Miles:
      selectedVehicle && selectedVehicle.electric_efficiency
        ? selectedVehicle.electric_efficiency
        : 0,
    milesDrivenAnnually,
    percentageDrivenElectric: isPHEV(selectedVehicle)
      ? electricMilesPortionForPhev
      : 100,
  });

  const homeChargingKwh =
    totalElectricVehicleKwh * ((100 - publicChargingPercentage) / 100);

  const publicChargingElectricCostPerMonth =
    (totalElectricVehicleKwh *
      (publicChargingCostInCentsPerKwh / 100) *
      (publicChargingPercentage / 100)) /
    12;

  const rateCostCalculator = new RateCostCalculator({
    typicalHomeLoadProfile: homeLoadProfile,
    currentRate: evaluatedCurrentRate,
    currentMonthlyBill,
    homeChargingKwh,
    chargingPatternLoadProfile: currentChargingPattern.loadProfile,
  });

  const gasolineCostCalculator = new GasolineCostCalculator({
    selectedVehicle,
    gasolinePriceInCentsPerGal,
    milesDrivenAnnually,
    electricMilesPortionForPhev,
    equivalentMilesPerGallon,
  });

  const evaluatedRates = Object.keys(rates).map((k) => ({
    ...rates[k],
    data: rates[k].data(currentTerritory, currentRateClimateZone),
  }));

  const rateTotals = calculateRateTotals({
    rates: evaluatedRates,
    currentRate: {
      ...currentRate,
      data: currentRate.data(currentTerritory, currentRateClimateZone),
    },
    rateCostCalculator,
    gasolineCostCalculator,
    publicChargingElectricCostPerMonth,
    selectedEnergyCostIds,
    isComparingLowestRateOnly,
  });

  const rsHome = rateTotals.find((obj) => obj.id.includes('-current')).home;
  const rlmEv = rateTotals.find((obj) =>
    obj.id.includes('-current')
  ).evCostValue;

  rateTotals.push({
    annual: 0,
    gas: 0,
    monthly: 0,
    isLowest: false,
    home: rsHome,
    ev: rlmEv,
    id: currentRate.id + '-with-ev',
    title: [`${currentRate.title[0]} (with EV)`],
  });

  if (currentRate.id === 'EV-TOU-5') {
    let tou5Pos = 0;
    let other = {};

    rateTotals.forEach((rate, i) => {
      if (rate.id === 'EV-TOU-5') {
        tou5Pos = i;
      } else {
        other = { ...rate };
      }
    });
    rateTotals[tou5Pos].gas = other.gas;
  }

  const labelHome = intl.formatMessage({
    id: 'savingsGraphHomeLabel',
    defaultMessage: 'Home',
  });
  const labelElectric = intl.formatMessage({
    id: 'savingsGraphEVLabel',
    defaultMessage: 'Electric Vehicle',
  });
  const labelGasoline = intl.formatMessage({
    id: 'savingsGraphGasLabel',
    defaultMessage: 'Gasoline',
  });
  const chartData = rateComparisonChartData({
    rateTotals,
    labelHome,
    labelElectric,
    labelGasoline,
  });

  return (
    <section className="container RateAdvisor">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h1 className="header">
            <FormattedMessage
              id="rateAdvisor.title"
              defaultMessage="Rate Adviser"
              description="Rate Adviser"
            />
          </h1>
          <h2 className="small-centered mb-bottom sub-header">
            <FormattedMessage
              id="rateAdvisor.sub"
              defaultMessage="Identify the lowest-cost electric rate for your EV. Adjust the inputs below to find the right electric rate for you."
              description="Rate Advisor Subtitle"
            />
          </h2>
        </div>
      </div>

      {!electricVehicles ||
        (!selectedVehicle && (
          <div className="text-center">
            <LoadingSpinner />
          </div>
        ))}

      {electricVehicles && selectedVehicle && (
        <div className="row">
          <div className="col-md-3">
            <RateOptionsWizard
              electricVehicles={electricVehicles}
              rates={evaluatedRates}
              rateTotals={rateTotals}
              selectedVehicle={selectedVehicle}
              utilityName="SCE"
              typicalDriverName="Californian"
              mostCommonRateName={rates[Object.keys(rates)[0]].title}
              bestSaving={bestSaving}
            />
            <InputPanel
              title={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'basicFilters',
                      defaultMessage: 'Basic Filters',
                    })
                  : 'Basic Filters'
              }
            >
              <SelectCurrentRate
                rates={Object.keys(rates).map((key) => rates[key])}
              />
              <SelectRateComparisonVehicle
                electricVehicles={electricVehicles}
                selectedVehicle={selectedVehicle}
              />
              <SlideMilesDrivenAnnually />
              <SelectChargingPattern chargingPatterns={chargingPatterns} />
            </InputPanel>
            <InputPanel
              title={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'homeAssumptions',
                      defaultMessage: 'Home Assumptions',
                    })
                  : 'Home Assumptions'
              }
              className="mb-4"
              defaultIsCollapsed={false}
            >
              <SlideCurrentMonthlyBill id="monthly-slider-filters" />
              {/* <GenericInputZipcode buttonText="Update Zip Code" /> */}
              {/* <SelectHasSolarAtHome /> */}
            </InputPanel>
            <InputPanel
              title={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'drivingAssumptions',
                      defaultMessage: 'Driving Assumptions',
                    })
                  : 'Driving Assumptions'
              }
              className="mb-4"
              defaultIsCollapsed={false}
            >
              {isPHEV(selectedVehicle) && (
                <SlideElectricMilesPortionForPhev
                  label={
                    intl.formatMessage
                      ? intl.formatMessage({
                          id: 'electricPortion',
                          defaultMessage: 'Electric Portion',
                        })
                      : 'Electric Portion'
                  }
                  description={(val) =>
                    `${val}% electric / ${100 - val} % gasoline`
                  }
                />
              )}
              <SlideGasolinePrice
                label={
                  intl.formatMessage
                    ? intl.formatMessage({
                        id: 'gasolinePrice',
                        defaultMessage: 'Gasoline Price',
                      })
                    : 'Gasoline Price'
                }
                description={(val) => `${FormatAsCents(val / 100)}/gallon`}
              />
              <SlideEquivalentMilesPerGallon />
              <SlidePublicChargingPercentage />
              <SelectPublicChargingCost />
            </InputPanel>
          </div>
          <div className="col-md-9">
            <div className="rate-comparison-title">
              <h3>
                <FormattedMessage
                  id="rateAdvisor.rateComparison"
                  defaultMessage="Rate Comparison"
                  description="Rate Comparison Title"
                />
              </h3>
            </div>
            <RateComparisonBanner
              rateTotals={rateTotals}
              selectedVehicle={selectedVehicle}
              bestSaving={bestSaving}
            />
            <RateComparisonChart
              title={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'pricingPlan',
                      defaultMessage: 'Pricing Plan Comparison',
                    })
                  : 'Pricing Plan Comparison'
              }
              chartData={chartData}
              className="d-md-block"
              setBestSaving={setBestSaving}
            />
            <div className="mt-4">
              <RateDetails
                rates={Object.keys(rates).map((k) => rates[k])}
                selectedRateDetailsId={userPrefs.get('selectedRateDetailsId')}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default RateComparison;

RateComparison.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
