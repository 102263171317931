import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import UserPrefsContext from '../../context/UserPrefs/UserPrefsContext';

// import ShowHideButton from "../ShowHideButton/ShowHideButton";
import {
  FormatAsDollars,
  FormatCarName,
  FormatAsThousandsOfDollars,
} from '../../utils/Helpers/Format';
import calcTotalCostOfOwnership from '../../functions/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import { VehicleCost } from '../../functions/vehicle/CostOfOwnership/calcs';

import {
  SALES_TAX,
  DOWN_PAYMENT,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH
} from '../../client_customizations/data/assumptions/ASSUMPTIONS';
// import getCarCostOfOwnership from '../../client_customizations/components/CostOfOwnership/getCarCostOfOwnership';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import times from 'lodash/times';
import { FormattedMessage, useIntl } from 'react-intl';

// import "./BreakevenChart.css";

const CHART_JS_OPTIONS = {
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const value = FormatAsDollars(
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        );
        const label = data.datasets[tooltipItem.datasetIndex].label || '';
        return ' ' + label + ': ' + value;
      },
      title: function (tooltipItem) {
        return 'year ' + tooltipItem[0].xLabel;
      },
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          weight: 700,
        },
        beginAtZero: true,
        callback: function (value) {
          return value < 1 ? '' : `Year ${value}`;
        },
      },
    },
    y: {
      ticks: {
        font: {
          weight: 700,
        },
        beginAtZero: true,
        callback: function (value) {
          return FormatAsDollars(value);
        },
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      onClick: function (e) {
        e.stopPropagation();
      },
      labels: {
        usePointStyle: true,
        boxWidth: 12,
      },
    },
    datalabels: {
      // chartjs-plugin-datalabels is enabled on this project,
      // so without returning "" the raw values will be shown for each data point
      formatter: () => '',
      align: 'end',
      anchor: 'end',
      color: '#333333',
      font: {
        weight: 700,
      },
    },
  },
};
const CHART_JS_MOBILE_OPTIONS = {
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const value = FormatAsDollars(
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        );
        const label = data.datasets[tooltipItem.datasetIndex].label || '';
        return ' ' + label + ': ' + value;
      },
      title: function (tooltipItem) {
        return 'year ' + tooltipItem[0].xLabel;
      },
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          weight: 700,
        },
        beginAtZero: true,
        callback: function (value) {
          return value < 1 ? '' : `${value} Yrs`;
        },
      },
    },
    y: {
      ticks: {
        font: {
          weight: 700,
        },
        beginAtZero: true,
        callback: function (value) {
          return FormatAsThousandsOfDollars(value);
        },
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      onClick: function (e) {
        e.stopPropagation();
      },
      labels: {
        usePointStyle: true,
        boxWidth: 12,
      },
    },
    datalabels: {
      // chartjs-plugin-datalabels is enabled on this project,
      // so without returning "" the raw values will be shown for each data point
      formatter: () => '',
      align: 'end',
      anchor: 'end',
      color: '#333333',
      font: {
        weight: 700,
      },
    },
  },
};


const breakevenCosts = (car, get, yearsOfOwnership, insuranceData, maintenanceData) => {
  return yearsOfOwnership.map((year) => {
    switch (year) {
      case 0:
        return parseInt(VehicleCost.afterIncentives(car));

      case yearsOfOwnership.length - 1:
        return calcTotalCostOfOwnership(
          car,
          get('purchaseMethod'),
          get('monthsOfOwnership'),
          get('milesDrivenAnnually'),
          get('interestRateAsBasisPoints'),
          get('electricMilesPortionForPhev'),
          get('gasolinePriceInCentsPerGal'),
          get('includeResaleValue'),
          process.env.REACT_APP_SLIDE_SALES_TAX
            ? get('salesTax')
            : SALES_TAX.value,
          process.env.REACT_APP_SLIDE_DOWN_PAYMENT
            ? get('downPayment')
            : DOWN_PAYMENT.value,
          process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING
            ? get('costPublicCharging')
            : 0,
          process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING
            ? get('publicChargingPercentage')
            : 0,
          process.env.REACT_APP_DYNAMIC_INSURANCE_COST
            ? get('municipality')
            : null,
          process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE
            ? get('electricityRate')
            : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value,
          insuranceData,
          maintenanceData,
          get('evRegistraionFeesRegion')
        ).summed.totalLastYear;

      default:
        return calcTotalCostOfOwnership(
          car,
          'cash',
          year * 12,
          get('milesDrivenAnnually'),
          get('interestRateAsBasisPoints'),
          get('electricMilesPortionForPhev'),
          get('gasolinePriceInCentsPerGal'),
          false, // do not include resale value in intermediate years
          process.env.REACT_APP_SLIDE_SALES_TAX
            ? get('salesTax')
            : SALES_TAX.value,
          process.env.REACT_APP_SLIDE_DOWN_PAYMENT
            ? get('downPayment')
            : DOWN_PAYMENT.value,
          process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING
            ? get('costPublicCharging')
            : 0,
          process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING
            ? get('publicChargingPercentage')
            : 0,
          process.env.REACT_APP_DYNAMIC_INSURANCE_COST
            ? get('municipality')
            : null,
          process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE
            ? get('electricityRate')
            : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value,
          insuranceData,
          maintenanceData,
          get('evRegistraionFeesRegion')
        ).summed.total;
    }
  });
};

const BreakevenChart = ({
  cars,
  forceUserPrefsPresets,
  onAnimationComplete,
  insuranceData,
  maintenanceData
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  if (!cars || cars.length === 0) return null;

  cars = cars.filter((car) => {
    return car !== null;
  });

  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;

  const yearsOfOwnership = times(get('monthsOfOwnership') / 12 + 1, (i) => i);

  const carCosts = cars.map((car) => {
    return breakevenCosts(car, get, yearsOfOwnership, insuranceData[car.handle], maintenanceData[car.handle]);
  });
  // const carTotalCosts = cars.map((car) => {
  //   return getCarCostOfOwnership(car, userPrefs, insuranceData);
  // });

  const priceChange = (carCosts) => {
    let years = [];
    for (let i = 0; i < carCosts[0].length; i++) {
      if (carCosts[0][i] < carCosts[1][i]) {
        years.push(i);
      }
    }
    if (years.length === carCosts[0].length) {
      return -1;
    } else if (years.length === 0) {
      return -2;
    }
    return years[0];
  };

  const renderSwitch = (param) => {
    switch (param) {
      case -2:
        return (
          <FormattedMessage
            id="tcoSavingsHeading"
            defaultMessage="The {car1} is not currently costs less to own than your existing vehicle"
            description="Cost to Breakdown Subtitle"
            values={{
              car1: FormatCarName(cars[0]),
            }}
          />
        );
      case -1:
        return (
          <FormattedMessage
            id="graph.costToBreakdown.subTitleAlwaysCheaper"
            defaultMessage="The {car1} is currently costs less to own than your existing vehicle"
            description="Cost to Breakdown Subtitle"
            values={{
              car1: FormatCarName(cars[0]),
            }}
          />
        );
      default:
        return (
          <FormattedMessage
            id="graph.costToBreakdown.subTitleCheaper"
            defaultMessage="The {car1} Becomes Cheaper After {time}"
            description="Cost to Breakdown Subtitle"
            values={{
              car1: FormatCarName(cars[0]),
              time:
                priceChange(carCosts) === 1
                  ? `1 +
                    ${intl.formatMessage({
                      id: 'year',
                      defaultMessage: 'year',
                    })}`
                  : `${priceChange(carCosts)} ${intl.formatMessage({
      id: "years",
      defaultMessage: "years",
    })}`,
            }}
          />
        );
    }
  };
  let subTitle = renderSwitch(priceChange(carCosts));
  
  let datasets = [
    {
      data: carCosts[1],
      label: FormatCarName(cars[1]),
      borderColor:  `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_COMPARE_BAR_COLOR_GASOLINE}`,
      backgroundColor:  `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_COMPARE_BAR_COLOR_GASOLINE}`,
      tension: 0.1,
      fill: false,
    },
    {
      data: carCosts[0],
      label: FormatCarName(cars[0]),
      borderColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_COMPARE_BAR_COLOR_ELECTRICITY}`,
      backgroundColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_COMPARE_BAR_COLOR_ELECTRICITY}`,
      tension: 0.1,
      fill: false,
    },
  ];

  if (cars.length === 3) {
    subTitle = (
      <p className="h3 my-3 graph-title">
        <FormattedMessage
          id="graph.costToBreakdown.subTitleThreeCars"
          defaultMessage="Compare the cumulative lifetime cost of {car1}, {car2} and {car3}"
          description="Cost to Breakdown Subtitle 3 Cars"
          values={{
            car1: FormatCarName(cars[0]),
            car2: FormatCarName(cars[1]),
            car3: FormatCarName(cars[2]),
          }}
        />
      </p>
    );
    datasets.push({
      data: carCosts[2],
      label: FormatCarName(cars[2]),
      borderColor: `#0000ff`,
      backgroundColor: 'transparent',
      lineTension: 0,
      borderDash: [10, 10],
    });
  }

  const title = (
    <h3>
      <div className="graph-title">{subTitle}</div>
    </h3>
  );

  const chartData = {
    labels: yearsOfOwnership,
    datasets: datasets,
  };


  const chartOptions = Object.assign({}, CHART_JS_OPTIONS, {});
  const chartMobileOptions = Object.assign({}, CHART_JS_MOBILE_OPTIONS, {});


  return (
    <div className="BreakevenChart input-well">
      {title}
      {window.innerWidth > 720 ? (
        <div className="line-box chart-container d-none d-lg-block d-xl-block">
          <div className="chart-info">
            <Line
              data={chartData}
              type="line"
              options={chartOptions}
              height="419px"
            />
          </div>
        </div>
      ) : (
        <div className="line-box chart-containerd-lg-block-none d-xl-block-none">
          <div className="chart-info">
            <Line
              data={chartData}
              type="line"
              options={chartMobileOptions}
              height="419px"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BreakevenChart;

BreakevenChart.propTypes = {
  car: PropTypes.object,
  comparedCar: PropTypes.object,
  forceUserPrefsPresets: PropTypes.bool,
  onAnimationComplete: PropTypes.func,
};
