import React from 'react';
import PropTypes from 'prop-types';
import { Bar as HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {
  FormatAsDollars,
  FormatInThousands,
} from './../../../utils/Helpers/Format';
import { useIntl } from 'react-intl';
import '../../../utils/chartSetup';

const CostToOwnComparisonChart = ({
  carNames,
  carsCosts,
  displayEvCostBreakdownOption,
  displayEvCostBreakdown,
}) => {
  let chartData = {};

  const intl = useIntl();

  chartData = {
    labels: carNames,
    datasets: [
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.vehicle',
          defaultMessage: 'Vehicle after Incentives',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_NET}`,
        data: carsCosts.map((carCost) => {
          return carCost.vehicle.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.taxesFees',
          defaultMessage: 'Taxes and Fees',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_TAXESFEES}`,
        data: carsCosts.map((carCost) => {
          return carCost.taxesFees.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.charger',
          defaultMessage: 'Charger/Install',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_CHARGER}`,
        data: carsCosts.map((carCost) => {
          return carCost.charger.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.electricity',
          defaultMessage: 'Electricity',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_ELECTRICITY}`,
        data: carsCosts.map((carCost) => {
          return carCost.electricity.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.gasoline',
          defaultMessage: 'Gasoline',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_GASOLINE}`,
        data: carsCosts.map((carCost) => {
          return carCost.gasoline.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.maintenance',
          defaultMessage: 'Maintenance',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_MAINTENANCE}`,
        borderColor: `#000000`,
        data: carsCosts.map((carCost) => {
          return carCost.maintenance.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.insurance',
          defaultMessage: 'Insurance',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_INSURANCE}`,
        data: carsCosts.map((carCost) => {
          return carCost.insurance.total;
        }),
        abbreviatedLabel: '',
      },
      {
        showLine: false,
        legend: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        datalabels: {
          align: 'right',
          color: '#142C41',
        },
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          value = FormatAsDollars(value);
          return ' ' + label + ': ' + value;
        },
        title: function (tooltipItems, data) {
          let total = tooltipItems.reduce(function (acc, item) {
            return acc + item.xLabel;
          }, 0);
          return tooltipItems[0].yLabel + ': ' + FormatAsDollars(total);
        },
      },
      yAlign: 'center',
      xAlign: 'center',
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: function (value) {
            value = FormatInThousands(value);
            return value;
          },
          beginAtZero: true,
        },
      },
      y: {
        barPercentage: 0.5,
        stacked: true,
        ticks: {
          font: {
            weight: 700,
            color: '#142C41',
          },
        },
      },
    },
    layout: {
      padding: {
        right: 75,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        onClick: function (e) {
          e.stopPropagation();
        },
        display: true,
        labels: {
          usePointStyle: true,
          boxWidth: 12,
          filter: function (item) {
            return !!item?.text?.trim();
          },
          font: {
            size: 14,
            weight: 400,
            textAlign: 'center',
            color: '#142C41',
          },
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          if (ctx.datasetIndex === datasets.length - 1) {
            let sum = 0;
            datasets.forEach((dataset) => {
              sum += dataset.data[ctx.dataIndex];
            });
            return FormatAsDollars(sum);
          } else if (value === 0) {
            return '';
          } else {
            return ctx.dataset.abbreviatedLabel;
          }
        },
        color: '#fff',
        font: {
          weight: 500,
        },
        anchor: 'center',
      },
    },
  };

  return (
    <HorizontalBar
      data={chartData}
      height={312}
      type="horizontalBar"
      options={options}
    />
  );
};

export default CostToOwnComparisonChart;

CostToOwnComparisonChart.propTypes = {
  carNames: PropTypes.array,
  carCosts: PropTypes.array,
  displayEvCostBreakdownOption: PropTypes.bool,
  displayEvCostBreakdown: PropTypes.bool,
  displayFuelOnly: PropTypes.bool,
};