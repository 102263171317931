import React, { Component } from 'react';
import 'url-search-params-polyfill';
import { Switch, Route, withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './Root.scss';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import PageLayout from '../../components/PageLayout/PageLayout';
import {
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH,
  SALES_TAX,
} from '../../data/assumptions/ASSUMPTIONS';

import experiencePreviewImage from '../../assets/images/experience-preview.png';
import metaImage from '../../assets/images/metaImage.png';
import eventsScreenshot from '../../assets/images/metaImage.png';

// Pages
import Home from '../../components/HomePage/Homepage';
import EVs from '../../../pages/EVs/EVs';
import EVsGraph from '../../../pages/EVs/EVsGraph';
import EV from '../../../pages/EV/EV';
import UsedEV from '../../../pages/UsedEV/UsedEV';
import UsedEVs from '../../../pages/UsedEVs/UsedEVs';
import Incentives from '../../../pages/Incentives/Incentives';
import MapPage from '../../pages/MapPage/MapPage';
import FAQ from '../../../pages/FAQ/FAQ';
import FourOhFour from '../../../pages/FourOhFour/FourOhFour';
import CompareVehicles from '../../components/CompareVehicles/CompareVehicles';
import HomeChargers from '../../../pages/HomeChargers/HomeChargers';
import Inventory from '../../../pages/Inventory/Inventory';
import Events from '../../../pages/Events/Events';
import CPAdditionalInfo from '../../components/ChargingPerksPilot/CPAdditionalInfo';

// Services
import fetchWrapper from '../../../utils/fetch/fetchWrapper';
import fetchElectricVehicles from '../../../services/fetchElectricVehicles';
import fetchGasolineVehicles from '../../../services/fetchGasolineVehicles';
import fetchIncentives from '../../../services/fetchIncentives';
import fetchDealers, { fetchDealersType } from '../../../services/fetchDealers';
import fetchVehicleIncentivesWithHandle from '../../../services/fetchVehicleIncentivesWithHandle';
import fetchHomeChargers from '../../../services/fetchHomeChargers';

// Utilities
import Uuid from '../../../utils/Uuid/Uuid';
import {
  loadState,
  persistState,
} from '../../../utils/LocalStorage/LocalStorage';
import loadUserPrefs from '../../../context/UserPrefs/loadUserPrefs';
import getUserPref from '../../../context/UserPrefs/getUserPref';
import USER_PREF_PRESETS from '../../../context/UserPrefs/USER_PREF_PRESETS';
import GaTracker from '../../../utils/GaTracker/GaTracker';
import isIE from '../../../utils/isIE';
import { MAP_TABS } from '../../../constants/mapTabs';

//Data

// import { DEALERS } from "../../data/dealers/DEALERS";

//Layouts
import ContentCenter from '../../components/PageLayout/BodyTemplates/ContentCenter';
import FrequentlyAskedQuestionsSection from '../../components/FrequentlyAskedQuestionsSection/FrequentlyAskedQuestionsSection';

//Hero Images
import chargingPerks from '../../assets/images/hero/chargingPerks.png';
import ChargingPerksPilot from '../../components/ChargingPerksPilot/ChargingPerksPilot';
import incomeQualifiedHero from '../../assets/images/hero/incomeQualified.jpg';
import IncomeQualified from '../../components/IncomeQualified/IncomeQualified';

import { FormattedMessage } from 'react-intl';
import {
  CHANGING_PERKS_PILOT_COLORADO,
  INCOME_QUALIFIED_COLORADO,
} from '../../../constants';
import fetchFaqs from '../../../services/fetchFaqs';
import RateComparison from '../../pages/RateComparison/RateComparison';

class Root extends Component {
  constructor(props) {
    super(props);

    const existingState = loadState() || {};
    const savedPrefs =
      existingState && existingState.userPreferences
        ? existingState.userPreferences
        : {};

    if (!savedPrefs.vehicleFormFactorFilters)
      savedPrefs.vehicleFormFactorFilters = {};
    if (!savedPrefs.vehicleFuelTypeFilters)
      savedPrefs.vehicleFuelTypeFilters = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};
    if (!savedPrefs.chargerFormFactorFilters)
      savedPrefs.chargerFormFactorFilters = {};
    if (!savedPrefs.chargerWifiFilter) savedPrefs.chargerWifiFilter = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};

    this.state = {
      uuid: existingState.uuid || Uuid(),
      ipData: existingState.ipData || null,
      electricVehicles: null,
      usedElectricVehicles: null,
      gasolineVehicles: null,
      incentives: null,
      incentivePrefsModalIsOpen: false,
      userLocation: null,
      userLocationNotFound: false,
      userLocationDealersNotFound: false,
      zipcodeUpdating: false,
      homeChargers: null,
      faqHomes: null,
      faqPages: null,

      userPreferences: loadUserPrefs(savedPrefs),
    };
    // Last resort to ensure that the user has a UUID
    if (!this.state.uuid) this.state.uuid = Uuid();

    this.loadElectricVehicleData = this.loadElectricVehicleData.bind(this);
    this.loadGasolineVehicleData = this.loadGasolineVehicleData.bind(this);
    this.loadIncentivesData = this.loadIncentivesData.bind(this);
    this.updateUserPreferences = this.updateUserPreferences.bind(this);
    this.geolocateUser = this.getUserZip.bind(this);
    this.incentivePrefsModalToggle = this.incentivePrefsModalToggle.bind(this);
    this.loadDealers = this.loadDealers.bind(this);
  }

  componentDidMount() {
    this.loadElectricVehicleData();
    this.loadGasolineVehicleData();
    this.getUserZip();
    this.loadZipcodeData();
    this.loadIncentivesData();
    this.loadDealers();
    this.loadHomeChargers();
    this.loadFaqs();

    GaTracker.initialize();
    const page = this.props.location.pathname + this.props.location.search;
    GaTracker.trackPage(page, { userId: this.state.uuid });
  }

  // TODO: this should be cleaner
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.userPreferences.zipcode !==
        prevState.userPreferences.zipcode ||
      this.state.userPreferences.householdSize !==
        prevState.userPreferences.householdSize ||
      this.state.userPreferences.householdIncome !==
        prevState.userPreferences.householdIncome ||
      this.state.userPreferences.canTurnInClunker !==
        prevState.userPreferences.canTurnInClunker ||
      this.state.userPreferences.taxFilingStatus !==
        prevState.userPreferences.taxFilingStatus
    ) {
      this.loadElectricVehicleData();
      this.loadIncentivesData();
    } else if (
      this.state.userPreferences.vehicleIdForIncentives !==
        prevState.userPreferences.vehicleIdForIncentives ||
      this.state.userPreferences.vehicleHandleForIncentives !==
        prevState.userPreferences.vehicleHandleForIncentives
    ) {
      this.loadIncentivesData();
    }

    if (
      this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode
    ) {
      this.loadZipcodeData();
      this.loadDealers();
    }

    let prevZip = prevState.userLocation ? prevState.userLocation.zip : null;
    if (this.state.userLocation && this.state.userLocation.zip !== prevZip) {
      this.loadDealers();
    }

    if (!this.state.userLocationNotFound && !this.state.zipcodeUpdating) {
      const { uuid, userPreferences, ipData } = this.state;
      persistState({ uuid, userPreferences, ipData });
    }

    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const nextPage = this.props.location.pathname + this.props.location.search;

    if (currentPage !== nextPage) {
      GaTracker.trackPage(nextPage, { userId: this.state.uuid });
    }

    if (this.props.language !== prevProps.language) {
      this.loadIncentivesData();
      this.loadElectricVehicleData();
    }

    if (
      this.state.userPreferences.zipcode !==
        prevState.userPreferences.zipcode ||
      this.state.userPreferences.chargerWifiFilter !==
        prevState.userPreferences.chargerWifiFilter
    ) {
      this.loadHomeChargers();
    }
  }

  async loadElectricVehicleData() {
    let params = {
      postcode: getUserPref('zipcode', this.state.userPreferences),
      household_size: getUserPref('householdSize', this.state.userPreferences),
      household_income: getUserPref(
        'householdIncome',
        this.state.userPreferences
      ),
      lang: this.props.language === 'EN' ? null : this.props.language,
    };

    try {
      const electricVehicles = await fetchElectricVehicles(params);
      if (!electricVehicles) return;
      this.setState({
        electricVehicles: electricVehicles.newElectricVehicles,
        usedElectricVehicles: electricVehicles.usedElectricVehicles,
      });
    } catch (e) {
      // TODO: handle errors here
    }
  }

  async loadGasolineVehicleData() {
    let params = {
      fuel_type: 'gas',
      postcode: getUserPref('zipcode', this.state.userPreferences),
      lang: this.props.language === 'EN' ? null : this.props.language,
    };

    try {
      const gasolineVehicles = await fetchGasolineVehicles(params);
      if (!gasolineVehicles) return;
      this.setState({ gasolineVehicles });
    } catch (e) {}
  }

  async loadFaqs() {
    fetchFaqs().then((data) => {
      const faqHomes = data.filter((faq) => faq.page === 'homePage');
      const faqPages = data.filter((faq) => faq.page === 'faqPage');


      this.setState({ faqHomes: faqHomes, faqPages: faqPages });
    });
  }

  async loadIncentivesData() {
    let params = {
      postcode: getUserPref('zipcode', this.state.userPreferences),
      vehicle_handle: getUserPref(
        'vehicleHandleForIncentives',
        this.state.userPreferences
      ),
      household_size: getUserPref('householdSize', this.state.userPreferences),
      household_income: getUserPref(
        'householdIncome',
        this.state.userPreferences
      ),
      turn_in_clunker: getUserPref(
        'canTurnInClunker',
        this.state.userPreferences
      )
        ? true
        : null,
      tax_filing_type: getUserPref(
        'taxFilingStatus',
        this.state.userPreferences
      ),
      include_used_vehicles:
        getUserPref('vehicleCondition', this.state.userPreferences) === 'new'
          ? true
          : false,
      lang: this.props.language === 'EN' ? null : this.props.language,
    };

    try {
      const incentives =
        params['vehicle_handle'] !== ''
          ? await fetchVehicleIncentivesWithHandle(params)
          : await fetchIncentives(params);
      if (!incentives) return;
      this.setState({ incentives });
    } catch (e) {
      // TODO: handle error
    }
  }

  async loadHomeChargers() {
    let params = {
      postcode: getUserPref('zipcode', this.state.userPreferences),
    };

    const wifiPrefs = getUserPref(
      'chargerWifiFilter',
      this.state.userPreferences
    );
    if (wifiPrefs.yes) {
      params.wifi = 'true';
    } else if (wifiPrefs.no) {
      params.wifi = 'false';
    }

    try {
      const homeChargers = await fetchHomeChargers(params);
      if (!homeChargers) return;
      this.setState({ homeChargers });
    } catch (e) {}
  }

  async loadDealers() {
    if (!process.env.REACT_APP_PAGES_DEALERS_ENABLED) {
      return;
    }

    const zipcode = getUserPref('zipcode', this.state.userPreferences);
    const distance = getUserPref(
      'vehicleDistanceFilter',
      this.state.userPreferences
    );

    const params = {
      postcode: zipcode,
    };

    if (distance) {
      params.distance = distance;
    } else {
      params.distance = 2500;
    }

    const dealers = await fetchDealers(params);
    const dealersTypes = await fetchDealersType();

    const enhancedDealers = dealers.flatMap((dealer) => {
      const foundDealers = dealersTypes.filter(
        (d) =>
          (d.dealerId === dealer.dealer_id ||
            d.dealershipHandle === dealer.dealership_handle) &&
          d.oem === dealer.oem
      );

      if (!foundDealers || foundDealers.length === 0) {
        return [dealer];
      } else {
        if (foundDealers.length >= 1) {
          return foundDealers.map((foundDealer) => {
            return {
              ...dealer,
              type: foundDealer.type,
              oem: foundDealer.oem,
            };
          });
        }
      }
    });

    this.setState({ dealerLocations: enhancedDealers });
  }

  loadZipcodeData() {
    const params = {
      postcode: getUserPref('zipcode', this.state.userPreferences),
      nearby_distance: isIE() ? 25 : 100,
    };

    let url = new URL(`${process.env.REACT_APP_API_HOST}/location`);

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    fetchWrapper(url, {
      method: 'GET',
    })
      .then((data) => {
        if (data.location) {
          const interestRateAsBasisPoints =
            data.location.national_financial_references[0]?.rates?.sofr * 100;

          const salesTaxValue =
            data.location.regional_financial_references[0]?.sales_tax
              .combined ?? SALES_TAX.value;

          const electricityRate =
            (data.location.regional_fuel_cost[0]?.electricity).toFixed(4) ??
            ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value;

          const evRegistraionFeesRegion =
            data.location.regional_financial_references[0]?.ev_registration_fees
              ?.region ?? 0;

          this.setState({
            userLocation: data.location,
            userLocationNotFound: false,
            zipcodeUpdating: false,
          });
          this.incentivePrefsModalToggle(false);
          this.updateUserPreferences({
            gasolinePriceInCentsPerGal:
              data.location.regional_fuel_cost[0].gasoline * 100,
            salesTax: salesTaxValue,
            electricityRate,
            evRegistraionFeesRegion,
            interestRateAsBasisPoints,
          });
        } else {
          this.setState({
            userLocationNotFound: true,
            zipcodeUpdating: false,
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        this.setState({
          userLocationNotFound: true,
          zipcodeUpdating: false,
        });
      });
  }
  getUserZip() {
    const params = {
      postcode:
        getUserPref('zipcode', this.state.userPreferences) ||
        USER_PREF_PRESETS['zipcode'],
    };
    const workingZipcode = getUserPref(
      'workingZipcode',
      this.state.userPreferences
    );

    if (workingZipcode) {
      params.postcode = workingZipcode;
    } else {
      const target = 'post-code';
      const match = document.cookie.match(
        new RegExp('(^| )' + target + '=([^;]+)')
      );
      if (match) {
        params.postcode = match[2];
        this.updateUserPreferences({ zipcode: match[2] });
      }
    }
    return params.postcode;
  }

  updateUserPreferences(newPrefs) {
    let prefs = Object.assign({}, this.state.userPreferences, newPrefs);
    let newState = {
      userPreferences: prefs,
    };
    if (
      newPrefs.zipcode &&
      this.state.userPreferences.zipcode !== newPrefs.zipcode
    )
      newState.zipcodeUpdating = true;
    this.setState(newState);
  }

  incentivePrefsModalToggle(override) {
    if (typeof override === 'boolean') {
      this.setState({ incentivePrefsModalIsOpen: override });
    } else {
      this.setState({
        incentivePrefsModalIsOpen: !this.state.incentivePrefsModalIsOpen,
      });
    }
  }

  render() {
    const ip = this.state.ipData ? this.state.ipData.ip : null;
    const uuid = this.state.uuid;
    const language = this.props.language;
    const changeLanguage = this.props.changeLanguage;
    const region = this.state.userLocation?.region;

    const userPrefs = {
      get: (key) => getUserPref(key, this.state.userPreferences),
      getPreset: (key) => USER_PREF_PRESETS[key],
      set: this.updateUserPreferences,
      zipcodeIsNotFound: this.state.userLocationNotFound,
      zipcodeIsUpdating: this.state.zipcodeUpdating,
      showIncentivePrefsModal: this.state.incentivePrefsModalIsOpen,
      toggleIncentivePrefsModal: this.incentivePrefsModalToggle,
      syncWorkingZipcode: () =>
        this.updateUserPreferences({
          zipcode: getUserPref('workingZipcode', this.state.userPreferences),
        }),
    };

    return (
      <UserPrefsContext.Provider value={userPrefs}>
        <Switch>
          {MAP_TABS.filter((tab) => tab.isEnabled).map((tab) => {
            return (
              <Route
                exact
                path={'/'}
                key={tab.id}
                render={(props) => {
                  return (
                    <PageLayout
                      zipcode={userPrefs.get('zipcode')}
                      language={language}
                      changeLanguage={changeLanguage}
                      props={props}
                      ip={ip}
                      uuid={uuid}
                      description="Learn more about electric vehicles (EVs) incentives"
                      disclaimers="EV Savings Calculator provides illustrative estimations throughout and does not guarantee the accuracy of any costs, savings, hardware specifications, or incentives estimates. Please refer to referenced sources and original equipment manufacturers for up-to-date costs, hardware specifications, and incentives information."
                      page="Home | PSEG"
                      title="PSEG"
                      image={metaImage}
                      userLocation={this.state.userLocation}
                      electricVehicles={this.state.electricVehicles}
                      usedElectricVehicles={this.state.usedElectricVehicles}
                    >
                      <Home
                        {...props}
                        electricVehicles={this.state.electricVehicles}
                        tabId={tab.id}
                        incentives={this.state.incentives}
                        userPreferences={this.state.userPreferences}
                        userLocation={this.state.userLocation}
                        ip={ip}
                        uuid={uuid}
                        title={'PSEG'}
                        faqs={this.state.faqHomes}
                      />
                    </PageLayout>
                  );
                }}
              />
            );
          })}
          {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
            <Route
              exact
              path="/faq"
              render={(props) => {
                return (
                  <PageLayout
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    description="Answers to Frequently Asked Questions about electric vehicles (EVs) and their incentives"
                    page="FAQ | PSEG"
                    activePage="faq"
                    userLocation={this.state.userLocation}
                    electricVehicles={this.state.electricVehicles}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                    language={language}
                    changeLanguage={changeLanguage}
                  >
                    <FAQ faqs={this.state.faqPages} language={language} />;
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
            <Route
              exact
              path="/vehicles"
              render={(props) => {
                return (
                  <PageLayout
                    zipcode={userPrefs.get('zipcode')}
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="Vehicles | PSEG"
                    title="Vehicles | PSEG"
                    ip={ip}
                    uuid={uuid}
                    description=""
                    activePage="vehicles"
                    userLocation={this.state.userLocation}
                    electricVehicles={this.state.electricVehicles}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                  >
                    <EVs
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
            <Route
              exact
              path="/used-vehicles"
              render={(props) => {
                return (
                  <PageLayout
                    zipcode={userPrefs.get('zipcode')}
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="vehicles"
                    title="Used Vehicles | PSEG"
                    activePage="used-vehicles"
                    ip={ip}
                    uuid={uuid}
                    userLocation={this.state.userLocation}
                    electricVehicles={this.state.electricVehicles}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                  >
                    <UsedEVs
                      {...props}
                      usedElectricVehicles={this.state.usedElectricVehicles}
                      ip={ip}
                      uuid={uuid}
                      zipcode={userPrefs.get('zipcode')}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
            <Route
              path="/used-vehicles/:evId"
              render={(props) => {
                return (
                  <PageLayout
                    zipcode={userPrefs.get('zipcode')}
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="vehicles"
                    activePage="used-vehicles"
                    ip={ip}
                    uuid={uuid}
                    disclaimers=""
                    userLocation={this.state.userLocation}
                    electricVehicles={this.state.electricVehicles}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                  >
                    <UsedEV
                      {...props}
                      electricVehicles={this.state.usedElectricVehicles}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                      zipcode={userPrefs.get('zipcode')}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
            <Route
              exact
              path="/incentives"
              render={(props) => (
                <PageLayout
                  zipcode={userPrefs.get('zipcode')}
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  page="Incentives | PSEG"
                  title="Incentives | PSEG"
                  description=""
                  disclaimers="Listed incentives may not be available at any given time. Listed incentives reflect an illustrative estimation of available incentives. {process.env.REACT_APP_FULL_COMPANY_NAME} does not recommend or endorse any particular automotive or insurance company."
                  activePage="incentives"
                  userLocation={this.state.userLocation}
                  electricVehicles={this.state.electricVehicles}
                  usedElectricVehicles={this.state.usedElectricVehicles}
                >
                  <Incentives
                    {...props}
                    electricVehicles={this.state.electricVehicles}
                    incentives={this.state.incentives}
                    image={experiencePreviewImage}
                    ip={ip}
                    uuid={uuid}
                    zipcode={getUserPref('zipcode', this.state.userPreferences)}
                    userLocation={this.state.userLocation}
                  />
                </PageLayout>
              )}
            />
          ) : null}
          {MAP_TABS.filter((tab) => tab.isEnabled).map((tab) => {
            return (
              <Route
                exact
                key={tab.id}
                path={tab.url}
                render={(props) => {
                  return (
                    <PageLayout
                      language={language}
                      changeLanguage={changeLanguage}
                      props={props}
                      ip={ip}
                      uuid={uuid}
                      page="Map | PSEG"
                      description={tab.description}
                      zipcode={getUserPref(
                        'zipcode',
                        this.state.userPreferences
                      )}
                      activePage={tab.activePage}
                      userLocation={this.state.userLocation}
                      electricVehicles={this.state.electricVehicles}
                      usedElectricVehicles={this.state.usedElectricVehicles}
                    >
                      <MapPage
                        {...props}
                        tabId={tab.id}
                        userLocation={this.state.userLocation}
                        ip={ip}
                        uuid={uuid}
                        dealerLocations={this.state.dealerLocations}
                        electricVehicles={this.state.electricVehicles}
                        usedElectricVehicles={this.state.usedElectricVehicles}
                        title={tab.title}
                        zipcode={getUserPref(
                          'zipcode',
                          this.state.userPreferences
                        )}
                      />
                    </PageLayout>
                  );
                }}
              />
            );
          })}
          {MAP_TABS.filter((tab) => tab.isEnabled).map((tab) => {
            return (
              <Route
                path="/vehicles/:evId"
                render={(props) => {
                  return (
                    <PageLayout
                      zipcode={userPrefs.get('zipcode')}
                      language={language}
                      changeLanguage={changeLanguage}
                      props={props}
                      page="Vehicles | PSEG"
                      ip={ip}
                      uuid={uuid}
                      description="Compare Electric Vehicles (EVs) by price, range and features."
                      disclaimers=""
                      activePage="vehicles"
                      userLocation={this.state.userLocation}
                      electricVehicles={this.state.electricVehicles}
                      usedElectricVehicles={this.state.usedElectricVehicles}
                    >
                      <EV
                        {...props}
                        electricVehicles={this.state.electricVehicles}
                        tabId={tab.id}
                        dealerLocations={this.state.dealerLocations}
                        userLocation={this.state.userLocation}
                        ip={this.state.ipData ? this.state.ipData : null}
                        uuid={uuid}
                        evPage={true}
                        zipcode={userPrefs.get('zipcode')}
                      />
                    </PageLayout>
                  );
                }}
              />
            );
          })}
          {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
            <Route
              exact
              path="/compare-vehicles"
              render={(props) => {
                return (
                  <PageLayout
                    zipcode={userPrefs.get('zipcode')}
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="Vehicles | PSEG"
                    title="Compare Vehicles | PSEG"
                    ip={ip}
                    uuid={uuid}
                    activePage="compare-vehicles"
                    userLocation={this.state.userLocation}
                    electricVehicles={this.state.electricVehicles}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                  >
                    <CompareVehicles
                      props={props}
                      ip={this.state.ipData ? this.state.ipData.ip : null}
                      uuid={this.state.uuid}
                      electricVehicles={this.state.electricVehicles}
                      gasolineVehicles={this.state.gasolineVehicles}
                      userLocation={this.state.userLocation}
                      compareUsedVehicles={false}
                      zipcode={userPrefs.get('zipcode')}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_COMPARISON_GRAPH_ENABLED ? (
            <Route
              exact
              path="/comparison-graph"
              render={(props) => {
                return (
                  <PageLayout
                    props={props}
                    page="cost vs range"
                    ip={ip}
                    uuid={uuid}
                    electricVehicles={this.state.electricVehicles}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                  >
                    <EVsGraph
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                      displayGraph={true}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
            <Route
              exact
              path="/home-chargers"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="home chargers"
                    userLocation={this.state.userLocation}
                    electricVehicles={this.state.electricVehicles}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                  >
                    <HomeChargers
                      {...props}
                      homeChargers={this.state.homeChargers}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_INVENTORY_ENABLED && (
            <Route
              exact
              path="/inventory"
              render={(props) => {
                return (
                  <PageLayout
                    props={props}
                    language={language}
                    changeLanguage={changeLanguage}
                    zipcode={userPrefs.get('zipcode')}
                    userLocation={this.state.userLocation}
                    ip={ip}
                    uuid={uuid}
                    page={process.env.REACT_APP_PAGES_INVENTORY_TITLE}
                  >
                    <Inventory
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      dealerLocations={this.state.dealerLocations}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          )}
          {process.env.REACT_APP_PAGES_EVENTS_ENABLED ? (
            <Route
              exact
              path="/events"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="events"
                    title={process.env.REACT_APP_PAGES_EVENTS_TITLE}
                    image={eventsScreenshot}
                    zipcode={userPrefs.get('zipcode')}
                    userLocation={this.state.userLocation}
                    electricVehicles={this.state.electricVehicles}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                  >
                    <Events {...props} ip={ip} uuid={uuid} />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          <Route
            exact
            path="/charging-perks"
            render={(props) => {
              return (
                <PageLayout
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  page="charging-perks"
                  title="Charging Perks | PSEG"
                  image={eventsScreenshot}
                  zipcode={userPrefs.get('zipcode')}
                  userLocation={this.state.userLocation}
                  electricVehicles={this.state.electricVehicles}
                  usedElectricVehicles={this.state.usedElectricVehicles}
                >
                  <img
                    src={chargingPerks}
                    alt="Hero"
                    style={{ minWidth: '100%', maxWidth: '100%' }}
                  />
                  <ContentCenter
                    pageHeaderLead="Charging Perks Pilot"
                    pageHeaderHeadLine="Get rewarded for enabling a cleaner, more efficient energy grid"
                    pageHeaderSubHeading="The Xcel Energy Charging Perks pilot program rewards electric vehicle (EV) drivers for charging their vehicles at times when it’s most beneficial to the energy grid. All you have to do is plug in—your automaker or evPulse and Xcel Energy will do the rest. Plus, you can get $100 just for signing up."
                    sectionStyle={{ backgroundColor: '#F6F8FB' }}
                  >
                    <ChargingPerksPilot />
                  </ContentCenter>
                  <ContentCenter
                    pageHeaderLead="FREQUENTLY ASKED QUESTIONS"
                    sectionStyle={{ backgroundColor: '#FFFFFF' }}
                  >
                    <FrequentlyAskedQuestionsSection
                      region={region}
                      applicableTo={CHANGING_PERKS_PILOT_COLORADO}
                    />
                    <CPAdditionalInfo />
                  </ContentCenter>
                </PageLayout>
              );
            }}
          />
          <Route
            exact
            path="/ev-rebate-co"
            render={(props) => {
              return (
                <PageLayout
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  page="IQ Rebate Page"
                  title="EV Rebate - Colorado | PSEG"
                  image={eventsScreenshot}
                  zipcode={userPrefs.get('zipcode')}
                  userLocation={this.state.userLocation}
                  electricVehicles={this.state.electricVehicles}
                  usedElectricVehicles={this.state.usedElectricVehicles}
                >
                  <img
                    src={incomeQualifiedHero}
                    alt="Hero"
                    style={{ minWidth: '100%', maxWidth: '100%' }}
                  />
                  <ContentCenter
                    pageHeaderLead={
                      <FormattedMessage
                        id="evRebateCOHeader"
                        defaultMessage="INCOME-QUALIFIED ELECTRIC VEHICLE REBATE"
                        description="EV Rebate CO Header"
                      />
                    }
                    pageHeaderHeadLine={
                      <FormattedMessage
                        id="evRebateCOSubHeader"
                        defaultMessage="Save up to $5,500 on an Electric Vehicle (EV)"
                        description="EV Rebate CO Sub Header"
                      />
                    }
                    pageHeaderSubHeading={
                      <FormattedMessage
                        id="evRebateCOSubHeaderDescription"
                        defaultMessage="Income-qualified customers can receive $3,000 off a used or $5,500 off a new EV when you buy or lease from a Colorado-based car dealer."
                        description="EV Rebate CO Sub Header Description"
                      />
                    }
                    sectionStyle={{ backgroundColor: '#F6F8FB' }}
                  >
                    <IncomeQualified />
                  </ContentCenter>
                  <ContentCenter>
                    <span
                      className="text-center"
                      style={{ display: 'inline-block' }}
                    >
                      If you fill out our application{' '}
                      <strong>before you shop and get pre-qualified,</strong>{' '}
                      you can get an{' '}
                      <strong>instant, non-taxable rebate</strong> when you buy
                      or lease your EV from a dealer in our{' '}
                      <strong>EV Dealer Network.</strong>
                    </span>
                  </ContentCenter>
                  <ContentCenter
                    pageHeaderLead={
                      <FormattedMessage
                        id="frequentlyAskedQuestions"
                        defaultMessage="FREQUENTLY ASKED QUESTIONS"
                        description="FREQUENTLY ASKED QUESTIONS"
                      />
                    }
                    sectionStyle={{ backgroundColor: '#FFFFFF' }}
                  >
                    <FrequentlyAskedQuestionsSection
                      region={region}
                      applicableTo={INCOME_QUALIFIED_COLORADO}
                    />
                  </ContentCenter>
                </PageLayout>
              );
            }}
          />
          <Route
            exact
            path="/compare-used-vehicles"
            render={(props) => {
              return (
                <PageLayout
                  zipcode={userPrefs.get('zipcode')}
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  page="Vehicles | PSEG"
                  title="Compare Used Vehicles | PSEG"
                  ip={ip}
                  uuid={uuid}
                  activePage="compare-vehicles"
                  userLocation={this.state.userLocation}
                  electricVehicles={this.state.electricVehicles}
                  usedElectricVehicles={this.state.usedElectricVehicles}
                >
                  <CompareVehicles
                    props={props}
                    ip={this.state.ipData ? this.state.ipData.ip : null}
                    uuid={this.state.uuid}
                    electricVehicles={this.state.usedElectricVehicles}
                    gasolineVehicles={this.state.gasolineVehicles}
                    userLocation={this.state.userLocation}
                    compareUsedVehicles={true}
                    zipcode={userPrefs.get('zipcode')}
                  />
                </PageLayout>
              );
            }}
          />

          <Route
            exact
            path="/rates"
            render={(props) => {
              return (
                <PageLayout
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  page="rates"
                  title="Drive Electric with PSEG | Rates"
                  zipcode={userPrefs.get("zipcode")}
                  userLocation={this.state.userLocation}
                  electricVehicles={this.state.electricVehicles}
                  usedElectricVehicles={this.state.usedElectricVehicles}
                >
                  <RateComparison
                    history={props.history}
                    electricVehicles={this.state.electricVehicles}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                    userLocation={this.state.userLocation}
                  />
                </PageLayout>
              );
            }}
          />

          <Route component={FourOhFour} />
        </Switch>
      </UserPrefsContext.Provider>
    );
  }
}
export default withRouter(Root);
