import {
HOME_ENERGY_COST_ID,
EV_ENERGY_COST_ID,
GAS_ENERGY_COST_ID,
} from "../constants/";

const rateComparisonChartData = ({ rateTotals, labelHome, labelElectric, labelGasoline }) => {
       const isMobile = window.innerWidth < 768;
       return {
         datasets: [
           {
             label: labelHome,
             id: HOME_ENERGY_COST_ID,
             data: rateTotals.map(
               (rateTotal) => rateTotal[HOME_ENERGY_COST_ID]
             ),
             backgroundColor: '#9BA4B8',
             barPercentage: 0.5,
             barThickness: isMobile ? 'flex' : 95,
             categoryPercentage: 1,
           },
           {
             label: labelElectric,
             id: EV_ENERGY_COST_ID,
             data: rateTotals.map((rateTotal) => rateTotal[EV_ENERGY_COST_ID]),
             backgroundColor: '#F0512C',
             barPercentage: 0.5,
             barThickness: isMobile ? 'flex' : 95,
             categoryPercentage: 1,
           },
           {
             label: labelGasoline,
             id: GAS_ENERGY_COST_ID,
             data: rateTotals.map((rateTotal) => rateTotal[GAS_ENERGY_COST_ID]),
             backgroundColor: '#142C41',
             barPercentage: 0.5,
             barThickness: isMobile ? 'flex' : 95,
             categoryPercentage: 1,
           },
         ],
         labels: [...rateTotals.map((rate) => rate.title)],
         ids: [...rateTotals.map((rate) => rate.id)],
       };
};

export default rateComparisonChartData;
